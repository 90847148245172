import {Col, Row} from "react-bootstrap";
import {useEffect, useRef} from "react";
import Particles from "react-particles-js";
import {Footer, MainButton} from "../commons";
import { Link } from "react-router-dom";

function HomePage() {

    const sectionRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const whyChooseUs = [
        {
            classname: "why-us round-image animationZoomOut",
            title: "Cutting Edge Solution",
            content: "Charm your customers with innovative ideas",
            icon: "/assets/creative.jpg"
        },
        {
            classname: "why-us round-image animationZoomOut",
            title: "Latest Tech",
            content: "Run your business. Leave your technology worries to us",
            icon: "/assets/latest-tech.jpg"
        },
        {
            classname: "why-us round-image animationZoomOut",
            title: "Simplified",
            content: "Use Technology to drive your business, not the other way",
            icon: "/assets/simplified.jpg"
        },
    ]
    const services = [
        {
            classname: "why-us round-short-image animationZoomOut",
            title: "Ed Tech Solutions",
            content: "",
            href: "ed-tech-solution",
            icon: "/assets/whyUs.jpg"
        },
        {
            classname: "why-us round-short-image animationZoomOut",
            title: "Digital Transformation",
            content: "",
            href: "digital-transformation",
            icon: "/assets/digital-collaboration.jpg"
        },
        {
            classname: "why-us round-short-image animationZoomOut",
            title: "Cyber Security and Forensic Services",
            content: "",
            href: "cyber-security-and-forensic-services",
            icon: "/assets/cyber-security.jpg"
        },
        {
            classname: "why-us round-short-image animationZoomOut",
            title: "Digital Marketing",
            content: "",
            href: "digital-marketing",
            icon: "/assets/digitalmarketing.jpg"
        },
        {
            classname: "why-us round-short-image animationZoomOut",
            title: "Data Analytics",
            content: "",
            href: "data-analytics",
            icon: "/assets/dataanalytics.jpg"
        },
        {
            classname: "why-us round-short-image animationZoomOut",
            title: "Data Science & Block Chain Technologies",
            content: "",
            href: "block-chain",
            icon: "/assets/blockchain.jpg"
        },
    ]

    const onButtonClick = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({
                behaviour: "smooth",
                block: "nearest"
            })
        }
    }

    return(
        <div className="home">
            <section className="section-1">
                <span className="title">
                    <h1 className="heading">Future Technologies</h1>
                    <hr/>
                    <p className="sub-heading">Transform to Digital Future with us</p>
                </span>
                <Particles
                    params={{
                        "particles": {
                            "number": {
                                "value": 80
                            },
                        },
                        "interactivity": {
                            "events": {
                                "onhover": {
                                    "enable": true,
                                    "mode": "repulse"
                                }
                            },
                        },
                    }}
                    height="100vh">
                </Particles>
                <MainButton content={"Enter into Future Technologies"} onButtonClick={onButtonClick} />
            </section>
            <section className="section-2" ref={sectionRef}>
                <Row xl={2} lg={2} md={2} xs={1}>
                    <Col className="vision-img animationLeft"/>
                    <Col className="vision-right animationRight">
                        <h1>Our Vision</h1>
                        <p className="content">To Design and develop Digital Solution for Business and Communities</p>
                    </Col>
                </Row>
            </section>
            <section className="section-3">
                <Row xl={2} lg={2} md={2} xs={1}>
                    <Col className="mission-left animationLeft">
                        <h1>Our Mission</h1>
                        <p className="content">Improving business of our clients through future technologies and solutions</p>
                    </Col>
                    <Col className="mission-img animationRight"/>
                </Row>
            </section>
            <section className="section-4">
                <h1>Why choose Future Technologies</h1>
                <hr className="underline"/>
                <Row>
                    {whyChooseUs.map((data) => (
                        <Col>
                            <img src={data.icon} className={data.classname}/>
                            <h4 className="title">{data.title}</h4>
                            <h6 className="content">{data.content}</h6>
                        </Col>
                    ))}
                </Row>
            </section>
            <section className="section-5">
                <h1>Services provided by us</h1>
                <hr className="underline"/>
                <Row>
                    {services.map((data) => (
                        <Col lg="4">
                            <Link to={data.href} target="_blank">
                                <img className={data.classname} src={data.icon}/>
                                <h5 className="title">{data.title}</h5>
                                <h6 className="content">{data.content}</h6>
                            </Link>
                        </Col>
                        
                    ))}
                </Row>
            </section>
            <Footer/>
        </div>
    )
}

export default  HomePage