import { useEffect } from "react";
import {Services} from "../commons";

function BigData() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const services = [
        {
            icon: "/assets/cloud-service.png",
            title: "Solutions for All Industries",
            content: [
                "Cloud application, SaaS",
                "Enterprise big data warehouse",
                "Operations management",
                "Operational analytics and planning",
                "Customer analytics"
            ]
        },
        {
            icon: "/assets/fraud.png",
            title: "Banking and Insurance Solutions",
            content: [
                "Risk management",
                "Fraud detection",
                "Customer behavior analysis"
            ]
        },
        {
            icon: "/assets/supply-chain.png",
            title: "Industrial Solutions",
            content: [
                "Predictive maintenance",
                "Condition monitoring",
                "Supply chain management (SCM)",
                "Asset tracking & monitoring",
                "Fleet management"
            ]
        },
        {
            icon: "/assets/ecommerce.png",
            title: "Retail and Ecommerce Solutions",
            content: [
                "Large-scale ecommerce",
                "Customer behavior analysis",
                "Online personalization",
                "In-store personalization",
                "Dynamic price optimization",
                "Inventory optimization"
            ]
        },
        {
            icon: "/assets/patient-monitoring.png",
            title: "Healthcare Solutions",
            content: [
                "Remote patient monitoring (RPM)",
                "Personalized care"
            ]
        },
        {
            icon: "/assets/telecommunication.png",
            title: "Telecom Solutions",
            content: [
                "Subscriber behavior analysis",
                "Network management and optimization"
            ]
        }
    ]

    return (
        <Services
          title="Big Data Consulting Services"
          desc="Big data consulting helps capture, store, and analyze big data in real time to uncover hidden patterns and correlations"
          banner="/assets/bigdata.jpg"
          services={services}
        />
    )
}

export default BigData