import { useEffect } from "react";
import {Services} from "../commons";

function RemoteWork() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const services = [
        {
            icon: "/assets/consulting.png",
            title: "Remote work consulting",
            content: [
                "Analyzing business processes and evaluating operational risks.",
                "Advising on workflow automation, cloud migration and suitable tools.",
                "Advising on security policies optimization.",
                "Designing a transition strategy."
            ]
        },
        {
            icon: "/assets/training.png",
            title: "Transition to remote work",
            content: [
                "Creating secure digital workplaces.",
                "Implementing, configuring and customizing powerful collaboration and productivity tools.",
                "Training remote employees on using new tools.",
                "Vulnerability assessment and IT support of the remote environment."
            ]
        },
        {
            icon: "/assets/productivity.png",
            title: "Remote productivity consulting and assessment",
            content: [
                "Evaluating the efficiency of employee productivity tracking.",
                "Suggesting robust monitoring tools and advising on their implementation.",
                "Implementing advanced analytics and reporting for full visibility into employee productivity."
            ]
        },
    ]
    return(
        <Services
          title="Remote Work Consulting and Enablement"
          desc="Remote work consulting helps companies transit their teams to a work-from-home mode."
          banner="assets/remotework.jpg"
          services={services}
        />
    )
}

export default RemoteWork