import { useEffect } from "react";
import { Header, Footer, Services } from "../commons";

function BlockChain() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const blockChainServices = [
        {
            icon: "/assets/strategy.png",
            title: "Strategy workshop and training",
            content: "Conducts Blockchain workshops to better understand blockchain technology, its potential applications and its working."
        },
        {
            icon: "/assets/blockchain.png",
            title: "Blockchain Consultancy",
            content: "We assess your existing solution, identify the need for a blockchain solution specific to your business use case and analyze how blockchain will add value to your business. We suggest the ideal technology and the possible solutions for the proposed project."
        },
        {
            icon: "/assets/coding.png",
            title: "Development of a Blockchain Proof of Concept",
            content: "Business unit leaders develop a blockchain concept that is tested for its market fit, likely customer acceptance, and competitive viability."
        },
        {
            icon: "/assets/app.png",
            title: "Blockchain Development",
            content: "Develop and deliver scalable blockchain apps that will innovate your business operations."
        },
    ]
    const bigDataservices = [
        {
            icon: "/assets/cloud-service.png",
            title: "Solutions for All Industries",
            content: [
                "Cloud application, SaaS",
                "Enterprise big data warehouse",
                "Operations management",
                "Operational analytics and planning",
                "Customer analytics"
            ]
        },
        {
            icon: "/assets/fraud.png",
            title: "Banking and Insurance Solutions",
            content: [
                "Risk management",
                "Fraud detection",
                "Customer behavior analysis"
            ]
        },
        {
            icon: "/assets/supply-chain.png",
            title: "Industrial Solutions",
            content: [
                "Predictive maintenance",
                "Condition monitoring",
                "Supply chain management (SCM)",
                "Asset tracking & monitoring",
                "Fleet management"
            ]
        },
        {
            icon: "/assets/ecommerce.png",
            title: "Retail and Ecommerce Solutions",
            content: [
                "Large-scale ecommerce",
                "Customer behavior analysis",
                "Online personalization",
                "In-store personalization",
                "Dynamic price optimization",
                "Inventory optimization"
            ]
        },
        {
            icon: "/assets/patient-monitoring.png",
            title: "Healthcare Solutions",
            content: [
                "Remote patient monitoring (RPM)",
                "Personalized care"
            ]
        },
        {
            icon: "/assets/telecommunication.png",
            title: "Telecom Solutions",
            content: [
                "Subscriber behavior analysis",
                "Network management and optimization"
            ]
        }
    ]
    return (
          <div className="block-chain">
            <Header/>
            <section className="section-1">
                <h1>Data Science and Block Chain Technologies</h1>
                <h3>Blockchain creates trust and transparency regarding data and can be used in an array of compelling applications.</h3>
                <img src="assets/bigdata.jpg" className="section-image" alt="big-data-img"/>
            </section>
            <section className="section-2">
                <h4>Data Science Services</h4>
                <hr/>
                <Services serviceOnly services={bigDataservices}/>
            </section>
            <section className="section-3">
                <h4>Block Chain Services</h4>
                <hr/>
                <Services serviceOnly services={blockChainServices}/>
            </section>
            <Footer/>
        </div>
    );
}

export default BlockChain