function Header() {

    const scrollToBottom = () =>{ 
        window.scrollTo({ 
          top: document.documentElement.scrollHeight, 
          behavior: 'auto'
        }); 
      }; 

    return(
        <>
        <div className="header">
            <a href="/">Home</a>
            <a onClick={scrollToBottom} style={{margin: '15px'}}>Contact us</a>
        </div>
        <hr/>
        </>
    )
}

export { Header }