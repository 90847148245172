import { useEffect } from "react";
import { Header, Footer, Services } from "../commons";

function CyberSecurity() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const cyberSecurityServices = [
        {
            icon: "/assets/vulnerability.png",
            title: "Vulnerability Assessment",
            content: "Our Vulnerability Assessment service will help you in identifying the weaknesses in your network and its systems. Get on top of your cybersecurity game today!"
        },
        {
            icon: "/assets/security-breach.png",
            title: "SIEM Services",
            content: [
                "In house SIEM solution - for organizations who have the expertise to manage the SIEM themselves.",
                "In house Managed SIEM solution - installed on customer premises but managed entirely by our team.",
                "SIEM as a service - A cloud based service offered from our 24X7 Security Operations Center (SOC) which continusly monitors the traffic and detects security events and activates measures to stop the cyberattack. This service also takes into consideration threat intelligence feeds from various industry sources in order to stop zero day attacks. Suitable for large enterprises and critical networks."
            ]
        },
        {
            icon: "/assets/network.png",
            title: "EndPoint Security",
            content: "EndPoint security or EndPoint protection refers to a methodology of protecting the corporate network that are remotely bridged to client devices. All these devices connecting remotely to the corporate network can be an entry point for an intruder. EndPoint security is a methodology to provide security to each of these devices in the corporate network."
        },
        {
            icon: "/assets/atm.png",
            title: "ATM Security",
            content: "A centralized security solution to protect, monitor, and control your ATM network."
        },
        {
            icon: "/assets/mobile-phone.png",
            title: "App Security",
            content: [
                "According to Gartner, more than 75% of all apps fail basic security tests. Which means that vulnerabilities in the mobile app can be exploited by malicious hackers to retrieve sensitive information about the smartphone user, leading to financial loss, identity theft, data loss.",
                "Our mobile APP security assessment cover all vulnerabilities identified by OWASP:",
                "Injection",
                "Broken authentication and session management",
                "Cross-site scripting",
                "Insecure direct object references",
                "Security misconfiguration",
                "Sensitive data exposure",
                "Missing function level access control",
                "Cross-site request forgery",
                "Using component with known vulnerabilities",
                "Unvalidated redirects and forwards"
            ]
        },
        {
            icon: "/assets/spyware.png",
            title: "Proactive Defense",
            content: "Our proactive defense solutions are based on a multi layer, collaborative security stratetgy."
        },
    ]

    const forensicServices = [
        {
            icon: "/assets/data-breach.png",
            title: "Data Breach",
            content: "If your company’s data has been breached or compromise, you need to know the extent of the breach and what impact it has had on your business and clients. Our cyber forensics team will work to find the evidence and the extent of the data breach. Our forensic team will help you find answers to important questions like: Was there really a breach? How did the breach affect the organization? Who attacked and how?"
        },
        {
            icon: "/assets/hacker.png",
            title: "Computer Forensics",
            content: [
                "Computer forensics is of interest not only to the police or detective agencies but also to commercial organizations in a variety of sectors. Our computer forensics team helps organizations in cases of: ",
                "Intellectual Property theft",
                "Employment disputes",
                "Inappropriate internet usage in the office",
                "Industrial espionage",
                "Fraud investigations",
                "Forgeries",
                "Bankruptcy investigations",
                "Regulatory compliance"
            ]
        },
        {
            icon: "/assets/compact-disk.png",
            title: "Disk Forensics",
            content: "With our Disk Forensics services, we are able to forensically to extract key evidential data from digital storage media like Hard disks, USB devices, CD, DVD, Flash drives, etc."
        },
        {
            icon: "/assets/smartphone.png",
            title: "Mobile Forensics",
            content: "With our Mobile Forensics services, we can forensically extract key evidential data from basic handsets to the latest smartphones from all well-known mobile manufacturers using a wide range of techniques. We are able to extract evidential / recoverable (i.e. active and deleted) data from mobile phones which includes:"
        }
    ]

    const products = [
        {
            icon: "/assets/egosecure.jpeg",
            title: "EGO SECURE",
            content: "A Matrix 42 group Germany company that provides an endpoint security solution that focuses on audit, analysis, encryptions, management, filtering, MDM."
        },
        {
            icon: "/assets/acelaboratory.jpeg",
            title: "PC-3000 – ACELab",
            content: "Hardware and software solutions intended for diagnosing, repairing, and data recovery from damaged disc drives such as SATA, PATA, SSD, SCSI, SAS, RAID, USB, and Flash Rom and also analysis of the data."
        },
        {
            icon: "/assets/mobiledit.jpeg",
            title: "MOBILedit",
            content: "Compelson Labs, a mobile forensic solution that forensically extracts key evidential data from smartphones of well-known mobile manufacturers using a wide range of techniques. It can also extract evidential/recoverable (i.e., active and deleted) data from mobile phones."
        },
        {
            icon: "/assets/hancomwith.jpeg",
            title: "HANCOM WITH",
            content: "HANCOM WITH, a mobile forensic solution that forensically extracts key evidential data from basic handsets to the latest smartphones from all well-known mobile manufacturers using a wide range of techniques. It can also extract evidential / recoverable (i.e., active and deleted) data from mobile phones, including physical extraction from the mainboard with JTAG from future phones and chip-off memory from damaged phones."
        }
    ]

    return(
        <div className="cyber-security">
            <Header/>
            <section className="section-1">
                <h1>CyberSecurity and Forensic Services</h1>
                <h3>Protect Your Applications and Network</h3>
                <h6 className="content">Our mantra is simple - effective cybersecurity can only be provided by experts, and we have the right expertise! </h6>
                <img src="assets/cyber-security.jpg" className="section-image"/>
            </section>
            <section className="section-2">
                <h4>Cyber Security Services</h4>
                <hr/>
                <Services serviceOnly services={cyberSecurityServices}/>
            </section>
            <section className="section-3">
                <h4>Cyber Forensic Services</h4>
                <hr/>
                <Services serviceOnly services={forensicServices}/>
            </section>
            <section className="section-4">
                <h4>Our Products</h4>
                <hr/>
                <Services serviceOnly services={products}/>
            </section>
            <Footer/>
        </div>
    )
}

export default CyberSecurity