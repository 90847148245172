import {Row, Col} from "react-bootstrap";

function Footer() {

    const socialMediaLinks = [
        {
            icon: "fa fa-facebook",
            link: "https://facebook.com"
        },
        {
            icon: "fa fa-twitter",
            link: "https://twitter.com"
        },
        {
            icon: "fa fa-linkedin",
            link: "https://linkedin.com"
        },
        {
            icon: "fa fa-whatsapp",
            link: "https://web.whatsapp.com"
        }
    ]
    return (
     <div className="footer">
        <Row>
            <Col>
                <p className="label">Contact Us</p>
                <div className="contact-section">
                    <p className="p-tag"><a className="contact" href="tel:+91-9310260507">+91-9310260507</a></p>
                    <p className="p-tag"><a className="contact" href="mailto:futuretechsofficial@gmail.com">futuretechsofficial@gmail.com</a></p>
                </div>
                
            </Col>
            <Col xs={4} md={4} lg={4}>
                <ul className="social-media">
                    {socialMediaLinks.map((data) => (
                        <li>
                            <a target="_blank" href={data.link} rel='noreferrer'><i className={data.icon}/></a>
                        </li>
                    ))}
                </ul>
            </Col>
            <p className="copyright">©2021, Future Technologies</p>
        </Row>
     </div>
    )
}

export { Footer }