import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {Services, Footer} from "../commons";

function DigitalOperationsConsulting() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const advantages = [
        {
            title: "Improve decision-making ",
            content: "by enabling high visibility into operational workflows."
        },
        {
            title: "Reduce risks of operations bottlenecks ",
            content: "by monitoring operations and related risks in real time."
        },
        {
            title: "Reduce reporting latency ",
            content: "due to automated analysis of operational data."
        },
        {
            title: "Raise machinery efficiency ",
            content: "due to automated equipment maintenance management."
        },
        {
            title: "Reduce inventory costs ",
            content: "by monitoring inventory levels and sending automated alerts for replenishment."
        },
        {
            title: "Improve workflow coordination between operations functions ",
            content: "thanks to collaboration tools."
        }, 
        {
            title: "Improve resource utilization ",
            content: "with minimized risks of over- or underutilization."
        }
    ]

    const services = [
        {
            icon: "/assets/analytics.png",
            title: "Data analytics",
            content: "for accurate production or service planning and operations optimization. Configurable dashboards for real-time visibility into operations data."
        },
        {
            icon: "/assets/workflow.png",
            title: "Digitalized business workflows",
            content: "(e.g., production/service order approvals) and advanced techs for process automation (e.g., inventory tracking with barcode or RFID technology and machinery condition monitoring with IoT) to reduce labor costs and the risks of human errors."
        },
        {
            icon: "/assets/secure.png",
            title: "Mature data security methods",
            content: "(authorized user access, user activity logs, data encryption) to protect sensitive operational data."
        }
    ]

    return (
        <div className="digital-op">
            <section className="section-1">
                <h1>Digital Operations Consulting</h1>
                <h6 className="content">Digital operations consulting will help you streamline production or service execution and reduce related costs. </h6>
                <img src="assets/digitaloperations.jpg" className="section-image" alt="digital-operations-img"/>
            </section>
            <section className="section-2">
                <h3>We deliver operations management software that helps:</h3>
                <Row>
                    {advantages.map((advantage) => (
                        <Col lg="4">
                            <img src="/assets/check.png" alt="advantages"/>
                            <p className="content"><b>{advantage.title}</b>{advantage.content}</p>
                        </Col>    
                    ))}
                </Row>
            </section>
            <section className="section-3">
                <h3>What Makes Our Operations Management Solutions Stand Out</h3>
                <Services serviceOnly services={services}/>
            </section>
            <Footer/>
        </div>
    )
}

export default DigitalOperationsConsulting