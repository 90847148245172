import { useEffect } from "react";
import {Services} from "../commons"

function ITSM() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const services = [
        {
            icon: "/assets/distributed-business.png",
            title: "Inefficiency of IT support across distributed business units",
            content: "Giving a single view on the IT processes and infrastructure, an ITSM solution will speed up IT support processes across a distributed IT environment."
        },
        {
            icon: "/assets/monitoring.png",
            title: "Complexity of monitoring vendors’ performance",
            content: "We can configure a vendor assessment functionality to make vendor evaluation and ranking easy, convenient and compliant with your company’s workflows"
        },
        {
            icon: "/assets/overlapping.png",
            title: "Failure to manage multiple IT service vendors with different SLAs and overlapping areas of responsibility",
            content: "Integrating your ITSM solution with those of your vendors’ as well as configuring Service Level Management functionality and creating different task workflows for different vendors can help your company successfully manage your cooperation with multiple vendors. With our ITSM knowledge, your company can deal with overlapping areas of responsibility through establishing systematic problem management and paying extra attention to finding the root cause."
        },
        {
            icon: "/assets/performance.png",
            title: "Inability to assess IT employees’ performance",
            content: "We can help you exploit IT employee performance information to measure KPIs for IT employees, assignment groups and regional units. It’ll make it easier to visualize performance trends and react to them."
        },
        {
            icon: "/assets/financial-mgmt.png",
            title: "Disorganization of Financial management for IT services",
            content: "With Financial Management functionality integrated into your ITSM solution, you can gain visibility into IT service costs, make the sources of IT costs evident, and reveal whether they stay within budget."
        },
        {
            icon: "/assets/problem-mgmt.png",
            title: "Lack of preventive efforts in IT service management ",
            content: "By designing and implementing Problem Management, our specialists can help your IT support analyze and root out the causes of similar incidents to prevent such incidents from occurring again"
        },
        {
            icon: "/assets/merger.png",
            title: "Difficulty in solving ITSM problems caused by merges and acquisitions",
            content: "We can help you import users, groups, companies, departments and locations into your instance, which will enable you to manage merges and acquisitions in an easy and convenient way."
        }
    ]

    return(
        <Services
          title="IT Service Management Consulting"
          desc="IT Service Management consulting helps companies tune their IT processes in line with business wants and needs."
          banner="assets/ITSM.jpeg"
          services={services}
        />
    )
}

export default ITSM