import {Services} from "../commons";
import { useEffect } from "react";

function DigitalMarketing() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const services = [
        {
            icon: "/assets/idea.png",
            title: "360° Digital Marketing",
            content: [
                "Content Ideation & Design",
                "Execution & Distribution"
            ]
        },
        {
            icon: "/assets/brand-engagement.png",
            title: "Unique Brand Engagement Solutions",
            content: [
                "Maximise awareness & engagement",
                "Never miss a lead"
            ]
        },
        {
            icon: "/assets/data-driven.png",
            title: "Data Driven Approach",
            content: [
                "Real time accurate data insights.",
                "Ensure your marketing is always tuned for high engagement & conversion."
            ]
        },
        {
            icon: "/assets/lead-generation.png",
            title: "Tech + Creative Expertise",
            content: [
                "Expertise across multiple channels",
                "Proven record generating leads"
            ]
        },
        {
            icon: "/assets/rising.png",
            title: "Constant Progress & Gains",
            content: [
                "Continuous improvements & learning of newer tech & platforms to strengthen your business"
            ]
        }

    ]

    return(
        <Services
          title="Digital Marketing"
          desc="Convert . Grow . Engage"
          banner="assets/digitalmarketing.jpg"
          services={services}
        />
    )
}

export default DigitalMarketing