import {Services} from "../commons";
import { useEffect } from "react";

function DataAnalytics() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const services = [
        {
            icon: "/assets/statistics.png",
            title: "Visualize Your Enterprise Data",
            content: "We bring all business data sources together, be it third-party integrated platforms, IoT sensors, or other."
        },
        {
            icon: "/assets/business-intelligence.png",
            title: "Get Answers in Real Time",
            content: "We set up Power BI’s natural language processing features so that you can easily query your data and receive immediate answers."
        },
        {
            icon: "/assets/data-analytics.png",
            title: "Advanced analytics",
            content: "With integrated R and Python scripts, our data science team can help you to leverage advanced analytics with Power BI."
        }
    ]
    return (
        <Services
          title="Data Analytics"
          desc="Unleash the power of your data, generate transformative insights, optimize processes, and enhance your ROI with enterprise analytics."
          banner="assets/dataanalytics.jpg"
          services={services}
        />
    )
}

export default DataAnalytics