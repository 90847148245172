import './App.scss';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import HomePage from "./homePage";
import ITSM from './ITSM';
import CyberSecurity from './CyberSecurity';
import BigData from './BigData';
import DigitalOperationsConsulting from './DigitalOperationsConsulting';
import RemoteWork from './RemoteWork';
import DigitalTransformation from './DigitalTransformation';
import EdTech from './EdTech';
import DataAnalytics from './DataAnalytics';
import DigitalMarketing from './DigitalMarketing';
import BlockChain from './BlockChain';

function App() {
  return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <HomePage/>
          </Route>
          <Route path="/it-service-mgmt">
            <ITSM/>
          </Route>
          <Route path="/cyber-security-and-forensic-services">
            <CyberSecurity/>
          </Route>
          <Route path="/big-data">
            <BigData/>
          </Route>
          <Route path="/digital-operations-consulting">
            <DigitalOperationsConsulting/>
          </Route>
          <Route path="/remote-work-consulting">
            <RemoteWork/>
          </Route>
          <Route path="/digital-transformation">
            <DigitalTransformation/>
          </Route>
          <Route path="/ed-tech-solution">
            <EdTech/>
          </Route>
          <Route path="/data-analytics">
            <DataAnalytics/>
          </Route>
          <Route path="/digital-marketing">
            <DigitalMarketing/>
          </Route>
          <Route path="/block-chain">
            <BlockChain/>
          </Route>
        </Switch>
      </BrowserRouter>
  );
}

export default App;
