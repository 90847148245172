import { Row, Col } from "react-bootstrap";
import { Header, Footer } from "./../../../commons";

function Services(props) {
    const services = (
      <>
      <Row className="services">
        {props.services.map((data) => (
          <Col lg="6">
            <img src={data.icon} className="icon" />
            <h6>
              <b>{data.title}</b>
            </h6>
            {typeof data.content === "object" ? (
              <ul className="content">
                {data.content.map((c) => (
                  <li>{c}</li>
                ))}
              </ul>
            ) : (
              <p className="content">{data.content}</p>
            )}
          </Col>
        ))}
      </Row>
      </>
    );
    
    if (props.serviceOnly) {
        return services;
    }
    
  return (
    <>
      <Header/>
      <div className="container">
        <section className="section-1">
          <h1>{props.title}</h1>
          <h6 className="content">{props.desc}</h6>
          <img src={props.banner} className="section-image" />
        </section>
        <section className="section-2">{services}</section>
      </div>
      <Footer />
    </>
  );
}

export {Services};
