import { useEffect } from "react";
import {Services, Footer, Header} from "../commons"

function DigitalTransformation() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const services = [
        {
            icon: "/assets/customer-services.png",
            title: "Digital customer experience",
            content: [
                "Customer mobile apps",
                "Customer portals and websites",
                "Ecommerce solutions",
                "Intelligent loyalty programs",
                "Customer service"
            ]
        },
        {
            icon: "/assets/connect.png",
            title: "Connected products",
            content: [
                "Digitalization of maintenance operations",
                "Optimization of device productivity",
                "Improving smart, connected products",
                "Uncovering new and more profitable revenue models"
            ]
        },
        {
            icon: "/assets/digital-hr.png",
            title: "Digital HR",
            content: [
                "Technology-driven hiring",
                "IT solutions for smooth onboarding",
                "Continuous-learning solutions based on mobile technology, augmented reality and chatbots.",
                "Digital tools for quick and effective staff assessment",
                "Advanced HR analytics"
            ]
        },
        {
            icon: "/assets/digital-workplace.png",
            title: "Digital workplace and digital collaboration",
            content: "We find best ways to upgrade a traditional workplace and transform employee experience with the IT solutions that improve employee wellness, help control work-life balance and drive performance."
        },
        {
            icon: "/assets/financial-mgmt.png",
            title: "Disorganization of Financial management for IT services",
            content: "With Financial Management functionality integrated into your ITSM solution, you can gain visibility into IT service costs, make the sources of IT costs evident, and reveal whether they stay within budget."
        },
        {
            icon: "/assets/merger.png",
            title: "Difficulty in solving ITSM problems caused by merges and acquisitions",
            content: "We can help you import users, groups, companies, departments and locations into your instance, which will enable you to manage merges and acquisitions in an easy and convenient way."
        },

    ]

    const OperationMgmt = [
        {
            icon: "/assets/analytics.png",
            title: "Data analytics",
            content: "for accurate production or service planning and operations optimization. Configurable dashboards for real-time visibility into operations data."
        },
        {
            icon: "/assets/workflow.png",
            title: "Digitalized business workflows",
            content: "(e.g., production/service order approvals) and advanced techs for process automation (e.g., inventory tracking with barcode or RFID technology and machinery condition monitoring with IoT) to reduce labor costs and the risks of human errors."
        },
        {
            icon: "/assets/secure.png",
            title: "Mature data security methods",
            content: "(authorized user access, user activity logs, data encryption) to protect sensitive operational data."
        }
    ]
    return (
        <div className="digital-transformation">
            <Header/>
            <section className="section-1">
                <h1>Digital Transformation Consulting</h1>
                <h3>Digital Transformation consulting helps companies tune their IT processes in line with business wants and needs.</h3>
                <img src="assets/digital-collaboration.jpg" className="section-image" alt="digital-collaboration-img"/>
            </section>
            <section className="section-2">
                <h4>Services</h4>
                <hr/>
                <Services serviceOnly services={services}/>
            </section>
            <section className="section-3">
                <h4>What Makes Our Digital Transformation Solution Stand Out</h4>
                <hr/>
                <Services serviceOnly services={OperationMgmt}/>
            </section>
            <Footer/>
        </div>
    )
}

export default DigitalTransformation