import { useEffect } from "react";
import {Services} from "../commons"

function EdTech() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const services = [
        {
            icon: "/assets/child.png",
            title: "Kaspersky Safe Kids",
            content: [
                "GPS tracking: Locate your kids wherever they go. Plus set a safe area for them to stay in & get alerted if they leave it.",
                "Screen-time controls: Manage your kids’ screen time per day, per device to fit their schedule & your style of parenting",
                "Website and App filters: Block adult content and customize a list of sites and apps your child can only visit with your permission.",
                "YouTube Safe Search: View your kids’ YouTube search history & stop them searching for inappropriate adult topics."
            ]
        },
        {
            icon: "/assets/teacher.png",
            title: "Teacher's learning Platform",
            content: [
                "Online-Offline LMS Mobile First Approach",
                "Integrated Virtual Classroom",
                "Monitoring & Evaluation (M&E) system",
                "Blind or Visually Impaired supported",
                "Personalized and Adaptive Learning",
                "Strong collaboration tools",
                "Online Admission and Fee Collection",
                "Quick Content creation",
                "Competency-based Learning"
            ]
        },
        {
            icon: "/assets/accreditation.png",
            title: "Accrediation Services",
            content: [
                "Institution to know its strengths, weaknesses, and opportunities through an informed review process",
                "Identification of internal areas of planning and resource allocation",
                "Collegiality on the campus",
                "Funding agencies look for objective data for performance funding",
                "Institutions to initiate innovative and modern methods of pedagogy",
                "New sense of direction and identity for institutions",
                "Intra and inter-institutional interactions"
            ]
        },
        {
            icon: "/assets/laboratory.png",
            title: "Hybrid Lab",
            content: [
                "Engineering Lab",
                "Corporate & Government",
                "Stem Education",
                "K-12 Education",
                "Vocational Education",
                "Higher Education",
                "Edutech outreach services"
            ]
        }
    ]
    return(
        <Services
          title="Ed Tech Solutions"
          desc="Digital operations consulting will help you streamline production or service execution and reduce related costs."
          banner="assets/whyUs.jpg"
          services={services}
        />
    )
}

export default EdTech